<template>
<v-col cols="12" class="">
  <v-row style="">
    <v-col cols="11" sm="12" md="12" lg="12" class="mx-auto header-section text-center mt-0 mb-5">
        <v-img src="../../assets/img/monnaie_pirate_logo.png" class="d-none d-sm-inline-flex" width="150"/>
        <v-img src="../../assets/img/monnaie_pirate_logo.png" class="d-inline-flex d-sm-none" width="100"/>
        <h1 class="font-main pb-1 pt-0 mb-5 pb-5 yellow--text text--darken-2">
          <small>Devenir membre d'équipage</small>
        </h1>

    </v-col>
    <v-col cols="11" sm="9" md="7" lg="5" class="mx-auto font-papyrus">
        Pour continuer à exister de façon totalement indépendante, 
        la Monnaie Pirate a besoin de la participation financière du plus grand nombre d'entre-vous.
        <br><br>
        La plateforme ne bénéficie d'aucune autre forme de financement, 
        c'est pourquoi nous vous invitons à participer avec ferveur en nous envoyant quelques €uros, selon vos capacités !
        <br><br>
        Merci à tous ceux qui participent.
    </v-col>
  </v-row>
  <v-row style="margin-bottom:25px;">
    <v-col cols="12" sm="6" md="4" lg="3" class="mx-auto" >
      <v-btn color="blue" large class="mt-5" id="btn-pay" block @click="startPaymentFreePrice">
        <v-icon>mdi-credit-card-outline</v-icon> Effectuer un paiement unique*
      </v-btn>
      <span class="d-block text-center mt-2">* paiement unique à <b>prix libre</b></span>
    </v-col>
  </v-row>

  <v-row>

    <v-col cols="11" sm="12" md="12" lg="10" class="mx-auto text-center my-5">
      <v-divider></v-divider>
      <h4 class="mt-5 font-papyrus primary--text"><v-icon  color="primary">mdi-chevron-down</v-icon> Choisir parmis les abonnements</h4>
      <v-divider class="my-5"></v-divider>

      <v-row class="mt-5">
        <template v-for="plan in $store.state.app.data.plan">
          <v-col :key="plan._id" v-if="plan.isRecurent" cols="12" sm="5" md="3" lg="3" style="" class="mx-auto px-1">
            <v-card outlined class="pa-1 card-select-plan" height="88"
                    @click="openPlan(plan)">
              <v-row>
                <v-col cols="5" class="text-left">
                    <img :src="baseUrl() + plan.imgBadgeCoin" 
                          class="img-circle d-inline-block mt-1 ml-1" 
                          width="70" height="70"/>
                </v-col>
                <v-col cols="7" class="pl-2 text-left">
                    <h1>{{ plan.amount }} €<small>/mois</small></h1>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </template>
      </v-row>
    </v-col>

  </v-row>

  <v-row style="margin-bottom:65px;">

    <v-col cols="11" sm="11" md="10" lg="8" 
          class="mx-auto bg-paper font-papyrus text-paper mt-5" 
          style="margin-bottom:30px;padding:35px!important; padding-top:50px;text-align:justify">
         <h1 class="font-papyrus primary--text text-center">Chers Pirates,</h1>
      <v-divider class="my-5"></v-divider>
        Nous savons que les temps sont dures pour la plupart d'entre-vous, et que beaucoup n'auront malheureusement pas les moyens de participer.
        C'est pourquoi l'application restera toujours accessible à tous <b>gratuitement</b>.
        <br><br>
        Mais nous comptons sur tous ceux qui le peuvent, pour nous soutenir autant que possible !
        <br><br>
        Le montant de la participation est libre, à partir de 2€.
        <br><br>
        Vous avez le choix entre les abonnement (paiements récurrents /mois) ou les paiements uniques à prix libre.
        Les paiement récurrents peuvent être interrompus à tout moment.
        <br><br>
        Tous les donateurs reçoivent une récompense sous forme de médaille pirate, en fonction du montant de leur participation.
        Cette médaille est affiché sur votre profil, pour attester de votre participation financière. 
        <br><br>
        Ceux qui donnent peuvent être fiers de leur geste, et sont considérés comme de véritables membres d'équipage, par tous les autres pirates !
        <br><br>
        En tant que membre d'équipage, vous n'avez qu'un seul véritable privilège : 
        celui de pouvoir jouer les uns contre les autres, en mode "multijoueur", sur tous nos mini-jeux actuels et futurs !
        <br><br>
        L'argent récolté servira à maintenir l'application en ligne, payer les serveurs, améliorer nos services, 
        et payer quelques bouteilles de rhum à tous ceux qui travaillent dans l'ombre, en contact avec le Royaume des Morts, 
        pour faire vivre ce projet chez les vivants !
        <br><br>
        Nous remercions par avance tous les pirates qui participeront, et vous souhaitons à tous excellente fin de vie avant la mort ! 
        <br><br><br>
        <div class="d-block text-right mr-5 pr-5">
        Black Sam & Alvilda
        </div>
    </v-col>


    <v-col cols="11" sm="11" md="10" lg="8" 
          class="mx-auto bg-paper font-papyrus text-paper mt-5" 
          style="margin-bottom:30px;padding:25px!important; padding-top:50px;text-align:justify">
      <h2 class="font-papyrus primary--text text-center">Évolutions de l'application</h2>
      <small class="text-center d-block">
        De nombreuses évolutions sont déjà dans les tiroirs !<br>
        Voici ce qui vous attend,
        si vous êtes assez nombreux à participer au financement...
      </small>
      <v-divider class="my-5"></v-divider>
      <ul>
        <li><v-icon class="mb-2">mdi-account-group-outline</v-icon> Améliorer les interactions entre pirates<br>
        <small>
            Parce que la solidarité entre pirates est primordiale,
            nous projetons de développer de nouveaux outils 
            pour simplifier et diversifier les moyens de communication inter-pirates.<br>
            (fil d'actualités, commentaires, devenir amis, photo de profil, etc)
        </small>
        </li>
        <li><v-icon class="mb-2">mdi-treasure-chest</v-icon> Une chasse au trésor IRL<br>
        <small>
            Pour gagner des PRT facilement en partant à la recherche des trésors cachés dans votre ville !<br>
        </small>
        </li>
        <li><v-icon class="mb-2">mdi-bed</v-icon> Un service "Air P&P"<br>
        <small>
            Notre propre système d'hébergement entre pirates ! <br>
            Utiliser des PRT pour partir en week-end ou en vacances... ça vous tente ?
        </small>
        </li>
        <li><v-icon class="mb-2">mdi-controller-classic</v-icon> De nouveaux mini-jeux<br>
        <small>
          Pour s'amuser toujours plus, et tenter de gagner un peu de monnaie facilement !
          Car la bataille navale n'est que le premier mini-jeux disponible en Monnaie Pirates...
          Mais bien d'autres pourraient voir le jour ! Jeux de dés, de cartes, casinos, dames, échecs, etc...
        </small>
        </li>
        <li><v-icon class="mb-2">mdi-calendar-multiselect-outline</v-icon> Un agenda commun<br>
        <small>Pour pouvoir se retrouver plus facilement sur tous les évènements pirates organisés près de chez-vous !</small>
        </li>
        <li><v-icon class="mb-2">mdi-calendar-multiselect-outline</v-icon> Faire des emprunt à la CCI<br>
        <small>Pour pouvoir financer vos projets locaux grâce à la Caisse Commune d'Investissement !</small>
        </li>
        <li><v-icon class="mb-2">mdi-progress-question</v-icon> Etc... Qui sait ce que l'avenir nous réserve ?!!!<br>
        <small>...</small>
        </li>
      </ul>
    </v-col>

    <v-col cols="12" class="text-center">
    <v-btn @click="scrollUp" elevation="0" fab><v-icon>mdi-arrow-up</v-icon></v-btn>
    </v-col>
  </v-row>

  <v-dialog v-model="showDialogPlan" max-width="500">
    <v-card outlined v-if="currentPlan != null">
      <v-toolbar color="transparent" elevation="0">
        <v-icon class="mr-2">mdi-credit-card-outline</v-icon> Souscrire à un abonnements
        <v-spacer/>
        <v-btn fab outlined small @click="showDialogPlan=false"><v-icon>mdi-close</v-icon></v-btn>
      </v-toolbar>
      <v-row class="mx-2">
      <v-col cols="12" sm="6" class="mx-auto">
        <v-card outlined class="pa-1 card-select-plan" height="88">
          <v-row>
            <v-col cols="5" class="text-left">
                <img :src="baseUrl() + currentPlan.imgBadgeCoin" 
                      class="img-circle d-inline-block mt-1 ml-1" 
                      width="70" height="70"/>
            </v-col>
            <v-col cols="7" class="pl-1 text-left">
                <h1>{{ currentPlan.amount }} €<small>/mois</small></h1>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12">
        En souscrivant à cet abonnement, vous participerez au financement de la plateforme 
        à hauteur de {{ currentPlan.amount }} €/mois (prélèvement automatique).
        <br><br>
        Vous obtiendrez votre médaille pirate virtuelle dès le premier paiement effectué !
        <br><br>
        L'abonnement est automatiquement activé dès le premier paiement.
        <br><br>
        Vous pouvez annuler cet abonnement à tout moment, dans la section "Paramètres" de votre compte.
        <br><br>
        <v-checkbox v-model="checkCGV" color="blue" label="" class="d-inline-block"></v-checkbox>
        <small>J'accèpte les conditions générales de ventes</small>
        <br>
        <small>* Paiement sécurisé via Stripe</small>
      <v-divider class="mt-5"></v-divider>
      </v-col>
      </v-row>
      <v-toolbar color="transparent" elevation="0">
        <v-img src="../../assets/img/stripe.png" width="30"></v-img>
        <v-spacer/>
        <v-btn @click="showDialogPlan=false" elevation="0" class="mr-2">Annuler</v-btn>
        <v-btn color="blue" elevation="0" class="" @click="startPayment">
          <v-icon>mdi-credit-card-outline</v-icon> Effectuer le premier paiement
        </v-btn>
      </v-toolbar>
    </v-card>
  </v-dialog>


  <v-row class="mb-0s">
    <v-col class="pa-0">
      <Footer/>
    </v-col>
  </v-row>

</v-col>
</template>

<style scoped>
.card-select-plan{
  border-radius:110px;
  background:transparent;
}
li{
  margin-top:25px;
}
</style>

<style>
.route-pay .badge-pirate{
  display:none !important;
}
</style>

<script>
import core from '../../plugins/core.js'
import '@/assets/css/home.css';
import Footer from '@/components/main-layout/Footer.vue'

export default {
  components: {
    Footer
  },
  data: () => ({
    isAbo: true,
    showDialogPlan: false,
    currentPlan: null,
    checkCGV: true
  }),

  mounted: function(){
    this.$store.dispatch('app/incStat', '/pay')
    this.loadPlans()
  },
  methods: {
    loadPlans(){
      this.$store.dispatch('app/fetchEntities', { entityType: 'plan', 
                                                  query : { "isActive": true },
                                                  sort: { position: 1 } 
                                                })
    },
    openPlan(plan){
      this.currentPlan = plan 
      this.showDialogPlan = true
    },
    startPayment(){
      this.$store.dispatch('pay/startPaiement', { planId: this.currentPlan._id })
    },
    startPaymentFreePrice(){
      if(this.planFreePrice != false)
        this.$store.dispatch('pay/startPaiement', { planId: this.planFreePrice._id })
    },
    baseUrl(){ return core.baseUrl() },
    scrollUp: function(){
      setTimeout(() => {
        this.$vuetify.goTo('#btn-pay')
      }, 100)
    },
  },
  computed:{
    planFreePrice: function(){
      let planFP = this.$store.state.app.data.plan.filter(plan => !plan.isRecurent && plan.key == "freeprice")
      console.log("planFP", planFP)
      if(planFP.length == 1) return planFP[0]
      else return false
    }
  }
}
</script>

<style>

</style>